<template>
  <div class="page">
    <Navbar title="技能编辑" type="SMP" />
    <div class="skill"></div>
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="skill.description"
          rows="2"
          autosize
          label="技能描述"
          type="textarea"
          maxlength="128"
          placeholder="请输入技能描述"
          show-word-limit
          :rules="[{ required: true, message: '请输入技能时长' }]"
        />
        <van-field
          v-model="skill.duration"
          name="时长(月)"
          label="时长(月)"
          type="digit"
          placeholder="请输入技能时长,必须为正整数"
          :rules="[{ required: true, message: '请输入技能时长,必须为正整数' }]"
        />
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
  </div>
</template>
<script>
import { Icon, Tag, Form, CellGroup, Field, Popup, Loading } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Loading.name]: Loading

  },
  data () {
    return {
      seq: '',
      personCode: '',
      skill: { description: '', duration: '' }
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveSkill()
  },
  methods: {
    async retrieveSkill () {
      var pd = { personCode: this.personCode, key: 'PSN_SKL_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.skill.description = content.description
        this.skill.duration = content.duration
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_SKL_ITEM', content: JSON.stringify(this.skill), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
